import {SocketContext, socket} from './context/socket';

import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import Dashboard from './components/Dashboard';
import Map from './components/Map';
import Monitor from './components/Monitor';
import Scoreboard from './components/Scoreboard';
import BackgroundLayer from './components/BackgroundLayer';
import BackgroundLayerEvil from './components/BackgroundLayerEvil';
import Riddles from './components/Riddles';

const App = () => {
  return (
    <SocketContext.Provider value={socket}>
    
      <Router>
        <Switch>
          <Route path="/monitor">
            <Monitor />
          </Route>
          <Route path="/backgroundlayer">
            <BackgroundLayer />
          </Route>
          <Route path="/backgroundlayerevil">
            <BackgroundLayerEvil />
          </Route>
          <Route path="/scoreboard">
            <Scoreboard />
          </Route>
          <Route path="/riddles">
            <Riddles />
          </Route>
          <Route path="/map">
            <Map />
          </Route>
          <Route path="/">
            <Dashboard />
          </Route>
        </Switch>
      </Router>
      
    </SocketContext.Provider>
  );
};

export default App;
