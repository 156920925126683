import React, {useState} from 'react';
import { motion, AnimatePresence } from "framer-motion";

const DataPoint = ({globals}) => {
      return (    
        <>
        
        {globals.activeDataPoint == "scuttled" &&
              <AnimatePresence>
                <motion.div 
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: .4}}
                  className="rightColGood">
                  <div className="title">
                    Ships Sunk
                  </div>
                  <motion.div 
                    className="value"
                    initial={{ opacity: 0, scale: 1.3 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 1.3 }}
                    transition={{ duration: .2, delay: .1}}
                    
                  >
                   {globals.scuttled}
                  </motion.div>
                </motion.div>
              </AnimatePresence>
        }      
        
        {globals.activeDataPoint == "sunk" &&    
            <AnimatePresence>
                <motion.div 
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: .4}}
                  className="rightColGood">
                      <div className="title">
                        Evil Fleet
                      </div>
                      <div className="boats value">
                        <AnimatePresence>
                            <motion.div 
                              initial={{ opacity: 0, scale: 1.3 }}
                              animate={{ opacity: 1, scale: 1 }}
                              exit={{ opacity: 0, scale: 1.3 }}
                              transition={{ duration: .2, delay: .1}}
                            >
                              {globals.boatSloop1Sunk && 
                                <motion.img
                                  initial={{ opacity: 0, scale: 1.3 }}
                                  animate={{ opacity: .9, scale: 1 }}
                                  exit={{ opacity: 0, scale: 1.3 }}
                                  transition={{ duration: .2, delay: .1}}
                                  src="/viewer/icon-boat-x.png" 
                                  className="boatX"/>
                              }
                              <img src="/viewer/icon-sloop.png"/>
                          </motion.div>
                          <motion.div 
                            initial={{ opacity: 0, scale: 1.3 }}
                            animate={{ opacity: 1, scale: 1 }}
                            exit={{ opacity: 0, scale: 1.3 }}
                            transition={{ duration: .2, delay: .1}}
                          >
                            {globals.boatEvilGalleonSunk && 
                              <motion.img
                                initial={{ opacity: 0, scale: 1.3 }}
                                animate={{ opacity: .9, scale: 1 }}
                                exit={{ opacity: 0, scale: 1.3 }}
                                transition={{ duration: .2, delay: .1}}
                                src="/viewer/icon-boat-x.png" 
                                className="boatX"/>
                            }
                            <img src="/viewer/icon-galleon.png"/>
                          </motion.div>
                          <motion.div 
                            initial={{ opacity: 0, scale: 1.3 }}
                            animate={{ opacity: 1, scale: 1 }}
                            exit={{ opacity: 0, scale: 1.3 }}
                            transition={{ duration: .2, delay: .1}}
                          >
                            {globals.boatSloop2Sunk && 
                              <motion.img
                                initial={{ opacity: 0, scale: 1.3 }}
                                animate={{ opacity: .9, scale: 1 }}
                                exit={{ opacity: 0, scale: 1.3 }}
                                transition={{ duration: .2, delay: .1}}
                                src="/viewer/icon-boat-x.png" 
                                className="boatX"/>
                            }
                            <img src="/viewer/icon-sloop.png"/>
                          </motion.div>
                      </AnimatePresence>
                    </div>
                </motion.div>
            </AnimatePresence>
            }      
            
            {globals.activeDataPoint == "drops" && 
                  <AnimatePresence>
                    <motion.div 
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: .4}}
                      className="rightColGood">
                      <div className="title">
                        Drops
                      </div>
                      <div className="value">
                        <AnimatePresence>
                          <motion.div 
                            className="dropRow"
                            style={{display: "inline-block"}}
                            initial={{ opacity: 0, scale: 1.3 }}
                            animate={{ opacity: 1, scale: 1 }}
                            exit={{ opacity: 0, scale: 1.3 }}
                            transition={{ duration: .2, delay: .1}}
                          >
                            {globals.drops} <img src="/viewer/coin.png"/>
                          </motion.div>
                        </AnimatePresence>
                      </div>
                    </motion.div>
                  </AnimatePresence>
          }      
          
          {globals.activeDataPoint == "solved" && 
              <AnimatePresence>
                <motion.div 
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: .4}}
                  className="rightColGood">
                  <div className="title">
                    Riddles Solved
                  </div>
                  <motion.div 
                    className="value"
                    initial={{ opacity: 0, scale: 1.3 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 1.3 }}
                    transition={{ duration: .2, delay: .1}}
                  >
                    {globals.riddlesSolved}
                  </motion.div>
                </motion.div>
              </AnimatePresence>
          }
            </>
        )
  };
  
  export default DataPoint;