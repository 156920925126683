import React, {useState, useEffect} from 'react';
import axios from "axios";

export default function Monitor() {
    const [globals, setGlobals] = useState();
    const [riddles, setRiddles] = useState();
    
    useEffect(() => {
        setInterval(function() {
            axios.get('http://137.184.4.157/api/globals')
                .then(function (response) {
                    setGlobals(response.data);
            });
            
            axios.get('http://137.184.4.157/api/riddles')
                .then(function (response) {
                    setRiddles(response.data);
            });
        }, 500);
    }, [])
      
    return (
        <>
        <pre>
            {JSON.stringify(globals, null, 2)}
        </pre>
        <pre>
            {JSON.stringify(riddles, null, 2)}
        </pre>
        </>
    )
}