import React from 'react';

class MapCoords extends React.Component {
  constructor(props) {
    super(props);
    this.state = { x: 0, y: 0 };
  }
  _onMouseMove(e) {
    this.setState({ x: ((e.nativeEvent.offsetX/window.innerHeight)*100).toFixed(8) + "%", y: ((e.nativeEvent.offsetY/window.innerHeight)*100).toFixed(8) + "%" });
  }
  render() {
    const { x, y } = this.state;
    return (
        <>
        <div className="coords">X: { x }, Y: { y }</div>
        <img 
            src={this.props.image}
            onMouseMove={this._onMouseMove.bind(this)}
            onClick={() => {
              this.props.coordsData(this.state);
            }} />
        </>
    )
  }
}

export default MapCoords;