import React, {useState, useContext, useCallback, useEffect, useRef} from 'react';
import {SocketContext} from '../context/socket';
import { motion, AnimatePresence } from "framer-motion";
import Countdown, {zeroPad} from 'react-countdown';

import '../Background.css';

const renderer = ({ hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return "DONE";
  } else {
    // Render a countdown
    return (
      <>
        {hours}:{zeroPad(minutes)}:{zeroPad(seconds)}
      </>
    )
  }
};

const BackgroundLayerEvil = () => {
  const socket = useContext(SocketContext);
  const isInitialMount = useRef(true);
  
  const [loaded, setLoaded] = useState(false);
  const [globals, setGlobals] = useState();
  const [riddles, setRiddles] = useState();
  
  useEffect(() => {
    socket.emit("getData");
  }, []);

  useEffect(() => {
    socket.on("setGlobals", (json) => {
      setGlobals(json);
    });
    
    socket.on("setRiddles", (json) => {
      setRiddles(json);
    });
  }, [socket]);
  
  const variants = {
    on: { 
      opacity: .8, 
      scale: 1
    },
    off: { 
      opacity: 0, 
      scale: 3
    },
  };

  return (    
    <>
    <div style={{height: window.innerHeight}}>
    {globals &&
      
        <div className="screenContainer evil" style={{width: 1920, height: 1080, backgroundImage: "url(/viewer/bkgnd-evil.png)" }}>
            
              <AnimatePresence>
              {globals.showCountdown &&
                <motion.div 
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: .4}}
                  className="timeRemaining">
                  <div className="title">
                    TIME REMAINING
                  </div>
                  <div className="value">
                    <Countdown
                      key={"timer-" + globals.timer}
                      date={globals.timer}
                      renderer={renderer}
                    />
                  </div>
                </motion.div>
              }
              </AnimatePresence>
                  
                  <AnimatePresence>
                    <motion.div 
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: .4}}
                    className="leftColEvil">
                    <div className="title">
                      Evil Fleet
                    </div>
                    <div className="boats value">
                      <AnimatePresence>
                          <motion.div 
                            initial={{ opacity: 0, scale: 1.3 }}
                            animate={{ opacity: 1, scale: 1 }}
                            exit={{ opacity: 0, scale: 1.3 }}
                            transition={{ duration: .2, delay: .1}}
                          >
                          {globals.boatSloop1Sunk && 
                            <motion.img
                              initial={{ opacity: 0, scale: 1.3 }}
                              animate={{ opacity: .9, scale: 1 }}
                              exit={{ opacity: 0, scale: 1.3 }}
                              transition={{ duration: .2, delay: .1}}
                              src="/viewer/icon-boat-x.png" 
                              className="boatX"/>
                          }
                          <img src="/viewer/icon-sloop.png"/>
                          </motion.div>
                        }
                      </AnimatePresence>
                      <AnimatePresence>
                          <motion.div 
                            initial={{ opacity: 0, scale: 1.3 }}
                            animate={{ opacity: 1, scale: 1 }}
                            exit={{ opacity: 0, scale: 1.3 }}
                            transition={{ duration: .2, delay: .1}}
                          >
                            {globals.boatEvilGalleonSunk && 
                              <motion.img
                                initial={{ opacity: 0, scale: 1.3 }}
                                animate={{ opacity: .9, scale: 1 }}
                                exit={{ opacity: 0, scale: 1.3 }}
                                transition={{ duration: .2, delay: .1}}
                                src="/viewer/icon-boat-x.png" 
                                className="boatX"/>
                            }
                            <img src="/viewer/icon-galleon.png"/>
                          </motion.div>
                      </AnimatePresence>
                      <AnimatePresence>
                          <motion.div 
                            initial={{ opacity: 0, scale: 1.3 }}
                            animate={{ opacity: 1, scale: 1 }}
                            exit={{ opacity: 0, scale: 1.3 }}
                            transition={{ duration: .2, delay: .1}}
                          >
                            {globals.boatSloop2Sunk && 
                              <motion.img
                                initial={{ opacity: 0, scale: 1.3 }}
                                animate={{ opacity: .9, scale: 1 }}
                                exit={{ opacity: 0, scale: 1.3 }}
                                transition={{ duration: .2, delay: .1}}
                                src="/viewer/icon-boat-x.png"
                                className="boatX"/>
                            }
                            <img src="/viewer/icon-sloop.png"/>
                          </motion.div>
                      </AnimatePresence>
                    </div>
                  </motion.div>
                  </AnimatePresence>
              
                  <AnimatePresence>
                      <motion.div 
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: .4}}
                        className="rightColEvil">
                        <div className="title">
                          Ships Sunk
                        </div>
                        <motion.div 
                          className="value"
                          initial={{ opacity: 0, scale: 1.3 }}
                          animate={{ opacity: 1, scale: 1 }}
                          exit={{ opacity: 0, scale: 1.3 }}
                          transition={{ duration: .2, delay: .1}}
                          
                        >
                          {globals.scuttled}
                        </motion.div>
                      </motion.div>
                  </AnimatePresence>
      </div>
      }
    </div>
    </>
  );
};

export default BackgroundLayerEvil;