import React, {useState, useContext, useCallback, useEffect, useRef} from 'react';
import {SocketContext} from '../context/socket';
import { motion, AnimatePresence } from "framer-motion";
import Countdown, {zeroPad} from 'react-countdown';


import '../Riddles.css';

const Riddles = () => {
  const socket = useContext(SocketContext);
  const isInitialMount = useRef(true);
  
  const [loaded, setLoaded] = useState(false);
  const [riddles, setRiddles] = useState();
  
  useEffect(() => {
    socket.emit("getData");
  }, []);

  useEffect(() => {
    socket.on("setRiddles", (json) => {
      setRiddles(json);
    });
  }, [socket]);
  
  const variants = {
    on: { 
      opacity: .8, 
      scale: 1
    },
    off: { 
      opacity: 0, 
      scale: 2
    },
  };

  return (    
    <>
    
      <div style={{height: window.innerHeight}}>
        {riddles &&
          <>
          <div className="pageBg" style={{width: 1920, height: 1080, backgroundImage: "url(/viewer/bkgnd-riddle.jpg)" }}/>
          
            <AnimatePresence>
              {!riddles.activeRiddle &&
              
            
              <motion.div 
                className="screenContainer riddle"
                style={{width: 1920, height: 1080, backgroundImage: "url(/viewer/bkgnd-riddle-all.jpg)" }}
                initial={{ opacity: 0, scale: 1.2 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 1.2 }}
                transition={{duration: .5}}
              >
                
                <div className="riddlesAll">
                
                  <img src="/viewer/icon-riddle-all-chests.png" className="chestsOverlay" />
                
                      <div className="header">
                        <motion.h2
                          initial={{ opacity: 0, scale: 1.2 }}
                          animate={{ opacity: 1, scale: 1 }}
                          exit={{ opacity: 0, scale: 1.2 }}
                          transition={{ duration: .4}}
                        >
                          The Shores of Plenty
                        </motion.h2>
                      </div>
                      <div className="header">
                        <motion.h2
                          initial={{ opacity: 0, scale: 1.2 }}
                          animate={{ opacity: 1, scale: 1 }}
                          exit={{ opacity: 0, scale: 1.2 }}
                          transition={{ duration: .4}}
                        >
                          The Wilds
                          </motion.h2>
                      </div>
                      <div className="header">
                        <motion.h2
                          initial={{ opacity: 0, scale: 1.2 }}
                          animate={{ opacity: 1, scale: 1 }}
                          exit={{ opacity: 0, scale: 1.2 }}
                          transition={{ duration: .4}}
                        >
                          The Ancient Isles
                          </motion.h2>
                      </div>
                      <div className="header">
                            <motion.h2
                          initial={{ opacity: 0, scale: 1.2 }}
                          animate={{ opacity: 1, scale: 1 }}
                          exit={{ opacity: 0, scale: 1.2 }}
                          transition={{ duration: .4}}
                        >
                          The Devil's Roar
                          </motion.h2>
                      </div>
                          {
                            Object.keys(riddles).map((key, i) => (
                                <>
                                {i >= 4 && 
                                    <>
                                    {key.split("_")[0] == "riddleEasy" && key.slice(-1) == "Q" &&
                                        <div className="questionAnswer">
                                        
                                        {riddles.riddleEasy &&
                                          <>
                                            <AnimatePresence>
                                              <motion.div 
                                                initial={{ opacity: 0 }}
                                                animate={{ opacity: 1 }}
                                                exit={{ opacity: 0 }}
                                                transition={{ duration: .4}}
                                                className="question">
                                                  {riddles[key]}
                                              </motion.div>
                                            </AnimatePresence>
                                                  
                                            <AnimatePresence>
                                              {riddles[key.slice(0,-1)] && 
                                              <motion.div 
                                                initial={{ opacity: 0, scale: 3 }}
                                                animate={{ opacity: 1, scale: 1 }}
                                                transition={{ duration: .4}}
                                                className="answer">
                                                  {riddles[key.slice(0,-1) + "A"]}
                                              </motion.div>
                                              }
                                            </AnimatePresence>
                                          </>
                                        }
                                        
                                        {!riddles.riddleEasy &&
                                          <img src="/viewer/icon-riddle-q.png" className="riddleQuestionMark" />
                                        }
                                        </div>
                                      }
                                      
                                      {key.split("_")[0] == "riddleMedium" && key.slice(-1) == "Q" &&
                                          <div className="questionAnswer">
                                          
                                          {riddles.riddleMedium &&
                                            <>
                                            <AnimatePresence>
                                              <motion.div 
                                                initial={{ opacity: 0 }}
                                                animate={{ opacity: 1 }}
                                                exit={{ opacity: 0 }}
                                                transition={{ duration: .4}}
                                                className="question">
                                                  {riddles[key]}
                                              </motion.div>
                                            </AnimatePresence>
                                                  
                                            <AnimatePresence>
                                              {riddles[key.slice(0,-1)] && 
                                              <motion.div 
                                                initial={{ opacity: 0, scale: 3 }}
                                                animate={{ opacity: 1, scale: 1 }}
                                                transition={{ duration: .4}}
                                                className="answer">
                                                  {riddles[key.slice(0,-1) + "A"]}
                                              </motion.div>
                                              }
                                            </AnimatePresence>
                                            </>
                                          }
                                          
                                          {!riddles.riddleMedium &&
                                            
                                            <img src="/viewer/icon-riddle-q.png" className="riddleQuestionMark" />
                                          }
                                          
                                          </div>
                                        }
                                        
                                        {key.split("_")[0] == "riddleHard" && key.slice(-1) == "Q" &&
                                            <div className="questionAnswer">
                                            
                                            {riddles.riddleHard &&
                                              <>
                                                <AnimatePresence>
                                                  <motion.div 
                                                    initial={{ opacity: 0 }}
                                                    animate={{ opacity: 1 }}
                                                    exit={{ opacity: 0 }}
                                                    transition={{ duration: .4}}
                                                    className="question">
                                                      {riddles[key]}
                                                  </motion.div>
                                                </AnimatePresence>
                                                      
                                                <AnimatePresence>
                                                  {riddles[key.slice(0,-1)] && 
                                                  <motion.div 
                                                    initial={{ opacity: 0, scale: 3 }}
                                                    animate={{ opacity: 1, scale: 1 }}
                                                    transition={{ duration: .4}}
                                                    className="answer">
                                                      {riddles[key.slice(0,-1) + "A"]}
                                                  </motion.div>
                                                  }
                                                </AnimatePresence>
                                                </>
                                              }
                                              
                                              {!riddles.riddleHard &&
                                                
                                                <img src="/viewer/icon-riddle-q.png" className="riddleQuestionMark" />
                                              }
                                            </div>
                                          }
                                      </>
                                }
                                </>
                            ))
                        }
                        
                </div>
                </motion.div>
              }
              </AnimatePresence>
              
              <AnimatePresence>
              {riddles.activeRiddle &&
              
                <motion.div 
                  className="screenContainer riddle"
                  style={{width: 1920, height: 1080, backgroundImage: "url(/viewer/bkgnd-riddle-single.jpg)" }}
                  initial={{ opacity: 0, scale: 1.2 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 1.2 }}
                  transition={{duration: .5}}
                >
                
                <div className="riddleSingle">
                  <AnimatePresence>
                  {riddles.activeRiddle.split("_")[1].slice(0,1) == 1 &&
                    <motion.h2
                      initial={{ opacity: 0, scale: 1.2 }}
                      animate={{ opacity: 1, scale: 1 }}
                      exit={{ opacity: 0, scale: 1.2 }}
                      transition={{ duration: .4}}
                    >
                      The Shores of Plenty
                    </motion.h2>
                  }
                  </AnimatePresence>
                  
                  <AnimatePresence>
                  {riddles.activeRiddle.split("_")[1].slice(0,1) == 2 &&
                  <motion.h2
                    initial={{ opacity: 0, scale: 1.2 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 1.2 }}
                    transition={{ duration: .4}}
                  >
                    The Wilds
                    </motion.h2>
                  }
                  </AnimatePresence>
                  
                  <AnimatePresence>
                  {riddles.activeRiddle.split("_")[1].slice(0,1) == 3 &&
                  <motion.h2
                    initial={{ opacity: 0, scale: 1.2 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 1.2 }}
                    transition={{ duration: .4}}
                  >
                    The Ancient Isles
                    </motion.h2>
                  }
                  </AnimatePresence>
                  
                  <AnimatePresence>
                  {riddles.activeRiddle.split("_")[1].slice(0,1) == 4 &&
                  <motion.h2
                    initial={{ opacity: 0, scale: 1.2 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 1.2 }}
                    transition={{ duration: .4}}
                  >
                    The Devil's Roar
                    </motion.h2>
                  }
                  </AnimatePresence>
                      
                  
                  <div className="questionAnswer">
                    <motion.div 
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: .4}}
                      className="question">
                        {riddles[riddles.activeRiddle + "Q"]}
                    </motion.div>
                          
                    <AnimatePresence>
                    {riddles[riddles.activeRiddle] &&
                      <motion.div 
                        initial={{ opacity: 0, scale: 3 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ duration: .4}}
                        className="answer">
                        {riddles[riddles.activeRiddle + "A"]}
                      </motion.div>
                    }
                    </AnimatePresence>
                  </div>
                </div>
              </motion.div>
              
              }
              </AnimatePresence>
          </>
        }
      </div>
    </>
  );
};

export default Riddles;