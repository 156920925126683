import React, {useState, useRef, useContext, useEffect} from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import ReactTooltip from 'react-tooltip';

import {SocketContext} from '../context/socket';
import { motion, AnimatePresence } from "framer-motion";
import Countdown, {zeroPad} from 'react-countdown';
import _ from 'lodash';

import MapCoords from './MapCoords.js';

import '../App.css';

function Switch({ isOn, ...props }) {
    const className = `switch ${isOn ? "on" : "off"}`;
    
    return (
        <motion.div animate className={className} {...props}>
            <motion.div animate />
        </motion.div>
    );
}

const renderer = ({ hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return "DONE";
  } else {
    // Render a countdown
    return (
      <>
        {hours}:{zeroPad(minutes)}:{zeroPad(seconds)}
      </>
    )
  }
};

const Dashboard = () => {
    const socket = useContext(SocketContext);
    const mapRef = useRef();
    const [timerField, setTimerField] = useState(100);
    
    const [globals, setGlobals] = useState();
    const [riddles, setRiddles] = useState();
    const [labels, setLabels] = useState();
    const [modalIsOpen, setIsOpen] = useState(false);
    const [activeCoordsField, setActiveCoordsField] = useState();
    
    const [score, setScore] = useState(0);
    const [scuttled, setScuttled] = useState(0);
    const [multiplier, setMultiplier] = useState(1);
    
    const [unsaved, setUnsaved] = useState({});

    const [boxPosition, setBoxPosition] = useState({x: 0, y: 0});
    
    useEffect(() => {
        socket.emit("getData");
    }, []);
    
    useEffect(() => {
        socket.on("setGlobals", (json) => {
          setGlobals(json);
          setScuttled(json.scuttled);
          setMultiplier(json.multiplier);
          setScore(json.score);
        });
        socket.on("setRiddles", (json) => {
          setRiddles(json);
        });
        socket.on("setLabels", (json) => {
          setLabels(json);
        });
    }, [socket]);
  
    useEffect(() => {
      tallyScore()
    }, [riddles]);
  
    useEffect(() => {
      if (globals) {
        tallyScore()
      }
    }, [scuttled, multiplier]);
    
    
    function tallyScore() {
      if (riddles) {
        let riddlesTables = [
            {"title": "riddleEasy_1","points": 1, "found": riddles.riddleEasy_1}, 
            {"title": "riddleEasy_2","points": 1, "found": riddles.riddleEasy_2}, 
            {"title": "riddleEasy_3","points": 1, "found": riddles.riddleEasy_3}, 
            {"title": "riddleEasy_4","points": 1, "found": riddles.riddleEasy_4}, 
            {"title": "riddleMedium_1","points": 2, "found": riddles.riddleMedium_1}, 
            {"title": "riddleMedium_2","points": 2, "found": riddles.riddleMedium_2}, 
            {"title": "riddleMedium_3","points": 2, "found": riddles.riddleMedium_3}, 
            {"title": "riddleMedium_4","points": 2, "found": riddles.riddleMedium_4}, 
            {"title": "riddleHard_1","points": 3, "found": riddles.riddleHard_1}, 
            {"title": "riddleHard_2","points": 3, "found": riddles.riddleHard_2}, 
            {"title": "riddleHard_3","points": 3, "found": riddles.riddleHard_3}, 
            {"title": "riddleHard_4","points": 3, "found": riddles.riddleHard_4}
        ];
        
        let riddlesAnswered = _.filter(riddlesTables, function(row) { return row.found == true; });
        let locationPoints = _.sumBy(riddlesAnswered, function(o) { return o.points; });
        let scoreCalc = Math.max(0, locationPoints - scuttled) * multiplier;
        
        updateGlobals("riddlesSolved", riddlesAnswered.length);
        updateGlobals("locationPoints", locationPoints);
        updateGlobals("score", scoreCalc);
      }
    }
    
    function openModal(key) {
        setIsOpen(true);
        setActiveCoordsField(key.slice(0,-1));
    }
      
    function closeModal() {
        setIsOpen(false);
        setActiveCoordsField();
    }
    
    function changeRiddlesJSON(riddleJsonName) {
        window.confirm("Are you sure you want to change Riddle DB?") &&
        socket.emit("switchRiddles", riddleJsonName);
    }
    
    function setAnimation(coords) {
        socket.emit("updateLocation", coords);
    }
    
    function updateGlobals(rowKey, rowValue) {
        setGlobals({...globals, [rowKey]: rowValue});
        socket.emit("updateData", "globals", rowKey, rowValue);
    }
    
    function updateRiddle(rowKey, rowValue) {
        setRiddles({...riddles, [rowKey]: rowValue});
        socket.emit("updateData", "riddles", rowKey, rowValue);
    }
    
    const decrementCount = (rowKey, rowValue) => {
        var updatedVal = rowValue > 0 ? rowValue - 1 : 0;
        setGlobals({...globals, [rowKey]: updatedVal});
        setScuttled(updatedVal);
        socket.emit("updateData", "globals", rowKey, updatedVal);
    }
    
    const incrementDrops = (rowKey, rowValue, amount) => {
        var updatedVal = rowValue + amount;
        setGlobals({...globals, [rowKey]: updatedVal});
        socket.emit("updateData", "globals", rowKey, updatedVal);
    }
    
    const decrementDrops = (rowKey, rowValue, amount) => {
        var updatedVal = rowValue - amount;
        setGlobals({...globals, [rowKey]: updatedVal});
        socket.emit("updateData", "globals", rowKey, updatedVal);
    }
    
    const zeroDrops = (rowKey, rowValue) => {
        var updatedVal = 0;
        setGlobals({...globals, [rowKey]: updatedVal});
        socket.emit("updateData", "globals", rowKey, updatedVal);
    }
    
    const incrementCount = (rowKey, rowValue) => {
        var updatedVal = rowValue + 1;
        setGlobals({...globals, [rowKey]: updatedVal});
        setScuttled(updatedVal);
        socket.emit("updateData", "globals", rowKey, updatedVal);
    }
    
    const zeroCount = (rowKey, rowValue) => {
        var updatedVal = 0;
        setGlobals({...globals, [rowKey]: updatedVal});
        setScuttled(updatedVal);
        socket.emit("updateData", "globals", rowKey, updatedVal);
    }
    
    const resetCounter = (minutes) => {
        var countdown = Date.now() + (minutes * 60)*1000;
        setGlobals({...globals, ["timer"]: countdown});
        updateGlobals("showCountdown", false);
        socket.emit("updateData", "globals", "timer", countdown);
    }
    
    const handleTextChange = (e, key) => {
        setUnsaved({...unsaved, [key]: 1});
        
        var updatedVal = e.target.value;
        setRiddles({...riddles, [key]: updatedVal});
    }
    
    const handleInputEnter = (e, key) => {
        if (e.key === 'Enter') {
            var updatedVal = e.target.value;
            setUnsaved({...unsaved, [key]: 0});
            setRiddles({...riddles, [key]: updatedVal});
            socket.emit("updateData", "riddles", key, updatedVal);
            socket.emit("refreshMapData");
        }
    }
    
    const revealAnswer = (key, val) => {
        setRiddles({...riddles, [key]: val});
        socket.emit("updateData", "riddles", key, val);
    }
    
    const acivateRiddle = (val) => {
        setRiddles({...riddles, "activeRiddle": val});
        socket.emit("updateData", "riddles", "activeRiddle", val);
    }
    
    const updateInput = (key, val) => {
        setUnsaved({...unsaved, [key]: 0});
        socket.emit("updateData", "riddles", key, val);
    }
    
    const handleCoordsUpdate = (coords) => {
        setRiddles({...riddles, [(activeCoordsField + "X")]: coords.x, [(activeCoordsField + "Y")]: coords.y});
        socket.emit("updateData", "riddles", (activeCoordsField + "X"), coords.x);
        socket.emit("updateData", "riddles", (activeCoordsField + "Y"), coords.y);
        socket.emit("refreshMapData");
        closeModal();
      };
    
    function showOnMap(event, id, key, cut) {
        key = key + "M";
        var val = true;
        setRiddles({...riddles, [key]: val});
        socket.emit("updateData", "riddles", key, val);
        setAnimation({id: id, visible: true, x: (riddles[key.slice(0,-1) + "X"]), y: (riddles[key.slice(0,-1) + "Y"]), scale: 2, duration: 6000, easing: "easeInOutQuint", cut: cut});
    }
    
    function hideOnMap(event, id, key) {
        key = key + "M";
        var val = false;
        setRiddles({...riddles, [key]: val});
        socket.emit("updateData", "riddles", key, val);
    }
    
    function handleTimerField(e) {
      setTimerField(e.target.value);
    }
    
      
    return (
        <>
        <div className="dashboard" style={{overflowY: "scroll", height: "100vh"}}>
            <h3>Producer Dashboard<br/> 
                
                <div style={{fontSize: ".8em", fontWeight: "normal"}}>
                    <a href="/backgroundlayer" target="_blank">Background Good</a> | <a href="/backgroundlayerevil" target="_blank">Background Evil</a> | <a href="/map" target="_blank">Map Viewer</a> | <a href="/riddles" target="_blank">Riddle Viewer</a> | <a href="/scoreboard" target="_blank">Scoreboard</a>
                </div>
                
                <div style={{fontSize: ".8em", fontWeight: "normal", margin: ".5em 0"}}>
                    <a href="/monitor" target="_blank">JSON Monitor</a> | <a href="/api/globals" target="_blank">Globals JSON (for vMix)</a> | <a href="/api/riddles" target="_blank">Riddles JSON (for vMix)</a>
                </div>
            </h3>
            
            <button style={{ width: 150, backgroundColor: "rgb(42,42,42)"}} onClick={(event) => {setAnimation({x: "25%", y: "1%", scale: 1.2, duration: 1, easing: "easeInOutQuad", cut: 0}); setTimeout(function(){setAnimation({x: "1%", y: "80%", scale: 2, duration: 15000, easing: "easeInOutQuad", cut: 0})}, 100); } }>Top R > Bottom L</button>
            <button style={{ width: 150, backgroundColor: "rgb(42,42,42)"}} onClick={(event) => {setAnimation({x: "1%", y: "1%", scale: 1.2, duration: 1, easing: "easeInOutQuad", cut: 0}); setTimeout(function(){setAnimation({x: "80%", y: "80%", scale: 2, duration: 15000, easing: "easeInOutQuad", cut: 0})}, 100); } }>Top L > Bottom R</button>
            <button style={{ width: 150, backgroundColor: "rgb(42,42,42)"}} onClick={(event) => {setAnimation({x: "0%", y: "0%", scale: 1, duration: 1, easing: "easeInOutQuad", cut: 0}); setTimeout(function(){setAnimation({x: ".1%", y: "38%", scale: 1.2, duration: 15000, easing: "easeInOutQuad", cut: 0})}, 100); } }>Full Map Pan</button>
            <button style={{ width: 150, backgroundColor: "rgb(42,42,42)"}} onClick={(event) => setAnimation({x: 0, y: 0, scale: 1, duration: 3000, easing: "easeInOutQuad", cut: 0}) }>Zoom Out</button>
            <br/>
            
            <button style={{ width: 150}} onClick={(event) => setAnimation({x: "10%", y: "22%", scale: 1.6, duration: 6000, easing: "easeInOutQuad", cut: 0}) }>Cut Shores of Plenty</button>
            <button style={{ width: 150}} onClick={(event) => setAnimation({x: "40%", y: "22%", scale: 1.6, duration: 6000, easing: "easeInOutQuad", cut: 0}) }>Cut The Wilds</button>
            <button style={{ width: 150}} onClick={(event) => setAnimation({x: "10%", y: "45%", scale: 1.6, duration: 6000, easing: "easeInOutQuad", cut: 0}) }>Cut The Ancient Isles</button>
            <button style={{ width: 150}} onClick={(event) => setAnimation({x: "35%", y: "36.5%", scale: 1.3, duration: 6000, easing: "easeInOutQuad", cut: 0}) }>Cut The Devils Roar</button>
            <button style={{ width: 150}} onClick={(event) => setAnimation({x: "58%", y: "59%", scale: 3, duration: 6000, easing: "easeInOutQuad", cut: 0}) }>Sea Dogs Tavern</button>
            <br/><br/>
            
            <div className="flexContainer">
            <div className="column col2">
                <table width="100%">
                    <tbody>
                    {globals && labels &&
                        <>
                        {
                            Object.keys(globals).map((key, i) => (
                                <>
                                {i >= 11 && i < 12 && 
                                    <tr key={"global" + i}>
                                        <td style={{width: "80%"}}>{labels[key]}</td>
                                        <td className="controls"><Switch isOn={globals[key]} onClick={() => updateGlobals(key, !globals[key])} /></td>
                                    </tr>
                                }
                                {i == 11 && 
                                <tr key={"global" + i}>
                                  <td colspan="2" style={{textAlign: "left"}}>
                                  <b>Show: </b>
                                    <button onClick={() => updateGlobals("activeDataPoint", "drops")} className={(globals.activeDataPoint == "drops" ? "active" : "")}>Drops</button>
                                    <button onClick={() => updateGlobals("activeDataPoint", "scuttled")} className={(globals.activeDataPoint == "scuttled" ? "active" : "")}>Ships Sunk</button>
                                    <button onClick={() => updateGlobals("activeDataPoint", "sunk")} className={(globals.activeDataPoint == "sunk" ? "active" : "")}>Evil Fleet</button>
                                    <button onClick={() => updateGlobals("activeDataPoint", "solved")} className={(globals.activeDataPoint == "solved" ? "active" : "")}>Riddles Solved</button>
                                  </td>
                                </tr>
                                }
                                {i == 12 && 
                                    <tr key={"global" + i}>
                                        <td style={{width: "100%", textAlign: "left"}} className="resetTimer" colspan="2">
                                        <br/>
                                        <br/>
                                          <div style={{display: "inline-block", textAlign: "left", width:"40%"}}>
                                            {labels[key]} &nbsp; &nbsp; 
                                            <Countdown
                                              key={"timer-" + globals.timer}
                                              date={globals.timer}
                                              renderer={renderer}
                                            />
                                            </div>
                                            <div style={{display: "inline-block", textAlign: "right", width:"60%"}}>
                                            <input type="text" style={{width: 70, marginLeft: 20, marginRight: 5, fontSize: "1em", padding: ".4em"}} value={timerField} onChange={handleTimerField} />
                                            <button onClick={() => resetCounter(timerField)}>SET MINUTES</button>
                                            </div>
                                        </td>
                                    </tr>
                                }
                                </>
                            ))
                        }
                        </>
                    }
                    </tbody>
                </table>
            </div>
                <div className="column col2">
                <table width="100%">
                    <tbody>
                    {riddles && labels &&
                        <>
                        {
                            Object.keys(riddles).map((key, i) => (
                                <>
                                {i < 3 && 
                                    <tr key={"riddle" + i}>
                                        <td style={{width: "auto"}}>{labels[key]}</td>
                                        <td width="100"><Switch isOn={riddles[key]} onClick={() => updateRiddle(key, !riddles[key])} /></td>
                                    </tr>
                                }
                                {i == 3 &&
                                    <tr>
                                        <td style={{width: "auto", textAlign: "left"}}>
                                            <b>Active Riddle: </b>
                                            {riddles.activeRiddle.split("_")[1] == 1 &&
                                            <div style={{display: "inline-block"}} className={riddles.activeRiddle.split("_")[0]}>
                                                The Shores of Plenty {riddles.activeRiddle.split("_")[0].replace("riddle", "")}
                                            </div>
                                            }
                                        
                                            {riddles.activeRiddle.split("_")[1] == 2 &&
                                            <div style={{display: "inline-block"}} className={riddles.activeRiddle.split("_")[0]}>
                                                The Wilds {riddles.activeRiddle.split("_")[0].replace("riddle", "")}
                                                </div>
                                            }
                                        
                                            {riddles.activeRiddle.split("_")[1] == 3 &&
                                            <div style={{display: "inline-block"}} className={riddles.activeRiddle.split("_")[0]}>
                                                The Ancient Isles {riddles.activeRiddle.split("_")[0].replace("riddle", "")}
                                                </div>
                                            }
                                        
                                            {riddles.activeRiddle.split("_")[1] == 4 &&
                                            <div style={{display: "inline-block"}} className={riddles.activeRiddle.split("_")[0]}>
                                                The Devil's Roar {riddles.activeRiddle.split("_")[0].replace("riddle", "")}
                                                </div>
                                            }
                                            {!riddles.activeRiddle &&
                                                <>
                                                Viewing All
                                                </>
                                            }
                                        </td>
                                        <td width="100">
                                            <button onClick={() => acivateRiddle("")}>View All</button>
                                        </td>
                                    </tr>
                                }
                                </>
                            ))
                        }
                        </>
                    }
                    </tbody>
                </table>
                </div>
                <div className="column col2">
                    <table width="100%">
                        <tbody>
                    {globals && labels &&
                        <>
                        {
                            Object.keys(globals).map((key, i) => (
                                <>
                                {i < 3 && 
                                    <tr key={"global" + i}>
                                        <td style={{width: "80%"}}>{labels[key]}</td>
                                        <td><Switch isOn={globals[key]} onClick={() => updateGlobals(key, !globals[key])} /></td>
                                    </tr>
                                }
                                {i == 3 &&
                                  <>
                                    <tr key={"global" + i}>
                                        <td  style={{textAlign: "left"}}>{labels[key]}: &nbsp;&nbsp;
                                          <b>{globals[key]}</b>
                                          
                                          <button style={{marginLeft: 50}} className="control decrement" onClick={() => decrementDrops(key, globals[key], 1000)}>-1</button>
                                          <button className="control increment" onClick={() => incrementDrops(key, globals[key], 1000)}>+1</button>
                                          <button className="control increment" onClick={() => incrementDrops(key, globals[key], 2000)}>+2</button>
                                          <button className="control increment" onClick={() => incrementDrops(key, globals[key], 3000)}>+3</button>
                                          <button className="control increment" onClick={() => incrementDrops(key, globals[key], 3000)}>+4</button>
                                          <button className="control increment" onClick={() => incrementDrops(key, globals[key], 5000)}>+5</button>
                                        </td>
                                        <td>
                                            <button className="control zero" onClick={() => zeroDrops(key, globals[key])}>RESET</button>
                                        </td>
                                    </tr>
                                  </>
                                }
                                {i == 6 && 
                                  <>
                                    <tr key={"global" + i}>
                                        <td style={{textAlign: "left"}}>{labels[key]}: {globals.locationPoints}</td>
                                    </tr>
                                  </>
                                }
                                {i >= 7 && i < 8 && 
                                    <tr key={"global" + i}>
                                        <td>
                                          {labels[key]}:  <b>{globals[key]}</b>
                                        
                                          <button style={{marginLeft: 50}} className="control decrement" onClick={() => decrementCount(key, globals[key])}>-1</button>
                                          <button className="control increment" onClick={() => incrementCount(key, globals[key])}>+1</button>
                                        </td>
                                        <td>
                                            <button className="control zero" onClick={() => zeroCount(key, globals[key])}>RESET</button>
                                        </td>
                                    </tr>
                                }
                                {i == 13 && 
                                  <>
                                    <tr key={"global" + i}>
                                        <td style={{textAlign: "left"}}>Score: {score}</td>
                                    </tr>
                                  </>
                                }
                                {i == 15 && 
                                  <>
                                    <tr key={"global" + i}>
                                        <td style={{textAlign: "left"}}>
                                        Multiplier: 
                                        <button style={{marginLeft: 10}} onClick={() => updateGlobals("multiplier", 1)} className={(globals.multiplier == 1 ? "active" : "")}>1x</button>
                                        <button onClick={() => updateGlobals("multiplier", 2)} className={(globals.multiplier == 2 ? "active" : "")}>2x</button>
                                        <button onClick={() => updateGlobals("multiplier", 3)} className={(globals.multiplier == 3 ? "active" : "")}>3x</button>
                                        </td>
                                    </tr>
                                  </>
                                }
                                </>
                            ))
                        }
                        </>
                    }
                        </tbody>
                    </table>
                </div>
                <div className="column col2 nodeControls">
                    <table>
                        <tbody>
                    {riddles && 
                        <>
                        {
                            Object.keys(riddles).map((key, i) => (
                                <>
                                {key.substr(-1) == "A" &&
                                <tr>
                                    <td className="riddleControls">
                                        <button data-tip data-for={"Tooltip_" + key.slice(0,-1)} className={"setRiddle" + (riddles.activeRiddle == key.slice(0,-1) ? " active" : "") + " riddle" + key.split("_")[0].replace("riddle", "") + " "} onClick={() => acivateRiddle(key.slice(0,-1))}>
                                        
                                            {key.split("_")[1].slice(0,1) == 1 &&
                                            <>
                                                The Shores of Plenty
                                            </>
                                            }
                                        
                                            {key.split("_")[1].slice(0,1) == 2 &&
                                            <>
                                                The Wilds
                                            </>
                                            }
                                        
                                            {key.split("_")[1].slice(0,1) == 3 &&
                                            <>
                                                The Ancient Isles
                                            </>
                                            }
                                        
                                            {key.split("_")[1].slice(0,1) == 4 &&
                                            <>
                                                The Devil's Roar
                                            </>
                                            }
                                        
                                        </button>
                                        <ReactTooltip id={"Tooltip_" + key.slice(0,-1)} place="right" type="dark" effect="float">
                                            <span style={{width: 200, display: "block", fontWeight: "normal"}}>
                                                <b>Question:</b><br/> {riddles[key.slice(0,-1) + "Q"]}<br/><br/>
                                                <b>Answer:</b><br/> {riddles[key.slice(0,-1) + "A"]}
                                            </span>
                                        </ReactTooltip>
                                    </td>
                                    <td style={{textAlign: "left", whiteSpace: "nowrap"}}>
                                    {!riddles[key.slice(0,-1)] &&
                                        <button className="revealAnswer" onClick={() => revealAnswer(key.slice(0,-1), true)}>
                                            Reveal Answer
                                        </button>
                                    }
                                    {riddles[key.slice(0,-1)] &&
                                        <button className="decrement" onClick={() => revealAnswer(key.slice(0,-1), false)}>
                                            Hide Answer
                                        </button>
                                    }
                                    {!riddles[(key.slice(0,-1)+"M")] &&
                                    <>
                                          <button
                                            className="revealMap"
                                              onClick={(event) => showOnMap(event, key.slice(6,7) + key.substr(-2), key.slice(0,-1), 0)}
                                          >
                                              Map Animate
                                          </button>
                                          <button
                                            className="revealMapCut"
                                              onClick={(event) => showOnMap(event, key.slice(6,7) + key.substr(-2), key.slice(0,-1), 1)}
                                          >
                                              Map Cut
                                          </button>
                                    </>
                                    }
                                    {riddles[(key.slice(0,-1)+"M")] &&
                                        <button
                                            className="decrement"
                                            onClick={(event) => hideOnMap(event, key.slice(6,7) + key.substr(-2), key.slice(0,-1))}
                                        >
                                            Hide On Map
                                        </button>
                                    }
                                    </td>
                                </tr>
                                }
                                </>
                            ))
                        }
                        </>
                    }
                        </tbody>
                    </table>
                </div>
            </div>
            
            <br/><br/>
            {globals &&
                <>
                    <button onClick={()=> changeRiddlesJSON("riddles1")} className={globals.activeDB == "riddles1" ? "activeDB" : ""}>Switch to Riddles Week 1</button>
                    <button onClick={()=> changeRiddlesJSON("riddles2")} className={globals.activeDB == "riddles2" ? "activeDB" : ""}>Switch to Riddles Week 2</button>
                    <button onClick={()=> changeRiddlesJSON("riddles3")} className={globals.activeDB == "riddles3" ? "activeDB" : ""}>Switch to Riddles Week 3</button>
                    <button onClick={()=> changeRiddlesJSON("riddles4")} className={globals.activeDB == "riddles4" ? "activeDB" : ""}>Switch to Riddles Week 4</button>
                    <button onClick={()=> changeRiddlesJSON("riddles5")} className={globals.activeDB == "riddles5" ? "activeDB" : ""}>Switch to Rehearsal Data</button>
                </>
            }
            <br/>
                
            <div className="flexContainer">
                {riddles && labels &&
                    <>
                        {
                            Object.keys(riddles).map((key, i) => (
                                <>
                                {i >= 4 &&
                                    <>                                    
                                    {key.slice(-1) == "X" &&
                                        <div className={"column grid " + key.split("_")[0]} key={"riddle" + i}>
                                            <b>{key.split("_")[1].slice(0,1)} {key.split("_")[0].replace("riddle", "") + " "}{labels[key]}</b><br/>
                                            <input type="text" value={riddles[key]} className={"alert" + unsaved[key]} onClick={() => openModal(key)} onKeyUp={(e) => handleInputEnter(e, key)} onChange={(e) => {handleTextChange(e, key)}}/>
                                        </div>
                                    }
                                    {key.slice(-1) == "Y" &&
                                        <div className={"column grid " + key.split("_")[0]} key={"riddle" + i}>
                                            <b>{key.split("_")[1].slice(0,1)} {key.split("_")[0].replace("riddle", "") + " "}{labels[key]}</b><br/>
                                            <input type="text" value={riddles[key]} className={"alert" + unsaved[key]} onClick={() => openModal(key)} onKeyUp={(e) => handleInputEnter(e, key)} onChange={(e) => {handleTextChange(e, key)}}/>
                                        </div>
                                    }
                                    
                                    {key.slice(-1) != "X" && key.slice(-1) != "Y" &&
                                    <>
                                        <div className={"column grid " + key.split("_")[0]} key={"riddle" + i}>
                                        
                                            {key.split("_")[1].slice(0,1) == 1 && key.slice(-1) == "Q" &&
                                            <div className="editRowLabel" style={{backgroundColor: "rgb(0,138,8)"}}>
                                                The Shores of Plenty
                                            </div>
                                            }
                                        
                                            {key.split("_")[1].slice(0,1) == 2 && key.slice(-1) == "Q" &&
                                            <div className="editRowLabel" style={{backgroundColor: "rgb(138,70,0)"}}>
                                                The Wilds
                                            </div>
                                            }
                                        
                                            {key.split("_")[1].slice(0,1) == 3 && key.slice(-1) == "Q" &&
                                            <div className="editRowLabel" style={{backgroundColor: "rgb(138,0,33)"}}>
                                                The Ancient Isles
                                            </div>
                                            }
                                        
                                            {key.split("_")[1].slice(0,1) == 4 && key.slice(-1) == "Q" &&
                                            <div className="editRowLabel" style={{backgroundColor: "rgb(36,0,138)"}}>
                                                The Devil’s Roar
                                            </div>
                                            }
                                            
                                            <b>{key.split("_")[1].slice(0,1)} {key.split("_")[0].replace("riddle", "") + " "}{labels[key]}</b><br/>
                                            <input type="text" value={riddles[key]} className={"alert" + unsaved[key]} onKeyUp={(e) => handleInputEnter(e, key)} onChange={(e) => {handleTextChange(e, key)}}/><br/>
                                            <button onClick={() => updateInput(key, riddles[key])}>UPDATE</button>
                                        </div>
                                        </>
                                    }
                                    </>
                                }
                                </>
                            ))
                        }
                    </>
                }
            </div>
        </div>
        
        <Modal
            isOpen={modalIsOpen}
            contentLabel="Map Modal"
            className="modal"
          >
            <button onClick={closeModal} className="closeIcon">close</button>
            <MapCoords image="/map/map.jpg" coordsData={handleCoordsUpdate}/>
          </Modal>
        </>
    );
};

export default Dashboard;