import React, {useState, useContext, useCallback, useEffect, useRef} from 'react';
import {SocketContext} from '../context/socket';

import '../Map.css';

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { motion, AnimatePresence } from "framer-motion";

const Map = () => {
  const socket = useContext(SocketContext);
  const mapImageRef = useRef();
  const mapRef = useRef();
  const isInitialMount = useRef(true);
  
  const offsetPctX = 20;
  const offsetPctY = 10;
  
  const [loaded, setLoaded] = useState(false);
  const [location, setLocation] = useState({x: 0, y: 0, scale: 1, duration: 2000, easing: "easeInOutQuint", cut: false});
  const [zoomed, setZoomed] = useState(false);
  const [mapNodes, setMapNodes] = useState([]);
  
  useEffect(() => {
    socket.emit("getData");
  }, []);

  useEffect(() => {
    socket.on("setRiddles", (json) => {
      var mapObjects = [];
      var counter = 0;
      var tempObj = {};
      
      Object.keys(json).map((key, i) => {
        if (key.split("_").length > 1) {
          
          counter++;
          var attr = key.split("_")[1];
          if (attr) {
            var id = attr.slice(0,1);
            
            switch(attr.slice(-1)) {
              case "A":
                var id = key.slice(6,7) + key.substr(-2);
                tempObj["id"] = id;
                tempObj["title"] = json[key];
                break;
              case "X":
                tempObj["x"] = json[key];
                break;
              case "Y":
                tempObj["y"] = json[key];
                break;
              case "M":
                tempObj["active"] = json[key];
                break;
            }
            
            
            if (counter > 5) {
              counter = 0;
              mapObjects.push(tempObj);
              tempObj = {};
            }
          }
        }
      });
      setMapNodes(mapObjects);
    });
    
    socket.on("broadcastLocation", (location, cut) => {
      setLocation(location, cut);
    });
  }, [socket]);

  useEffect(() => {
    if (isInitialMount.current) {
       isInitialMount.current = false;
    } else {
      var adjX = Math.abs(convertPct("x", location.x));
      var adjY = Math.abs(convertPct("y", location.y));
      var startAdjX = Math.abs(convertPct("x", 100 - parseFloat(location.x) + "%"));
      var startAdjY = Math.abs(convertPct("y", 100 - parseFloat(location.y) + "%"));
      
      if (adjX != 0 && adjY != 0) {
        adjX = adjustCoords("x", location.x);
        adjY = adjustCoords("y", location.y);
        
        adjX = adjX > -150 ? -150 : adjX;
        adjY = adjY > -150 ? -150 : adjY;
        adjX = adjX < -1850 ? -1850 : adjX;
        adjY = adjY < -2650 ? -2650 : adjY;
      }
      
      if (location.cut) {
        mapRef.current.setTransform(-getRandomInt(100, 300), -getRandomInt(200,800), 1.2, .1);
      }
      
      setTimeout(function(){
        mapRef.current.setTransform(adjX, adjY, location.scale, location.duration, location.easing);
      }, 150);
      
      if (location.scale == 2) {
        setZoomed(true);
      }
      
      if (location.scale < 2) {
        setZoomed(false);
      }
    }
    
    const elementsIndex = mapNodes.findIndex(element => element.id == location.id );
    let newArray = [...mapNodes];
    newArray[elementsIndex] = {...newArray[elementsIndex], active: location.visible};
    setMapNodes(newArray);
    
  }, [location]);
  
  function adjustCoords(type, value) {
    if (type == "x") {
      return ((-parseFloat(value)+offsetPctX)/100*1920)/.501
    }
    
    if (type == "y") {
      return ((-parseFloat(value)+offsetPctY)/100*1080)/.2817;
    }
  }
  
  function convertPct(type, value) {
    if (type == "x") {
      return -parseFloat(value)/100*1920;
    }
    
    if (type == "y") {
      return -parseFloat(value)/100*1920;
    }
  }
  
  function getRandomInt(min, max) {
    return Math.random() * (max - min) + min;
  }
  
  
  
  const variants = {
    on: { 
      opacity: .8, 
      scale: 1
    },
    off: { 
      opacity: 0, 
      scale: 3
    },
  };

  return (    
    <>
    <div style={{height: window.innerHeight}}>
        <div className="mapContainer" style={{width: 1920, height: 1080}}>
          <TransformWrapper
            doubleClick={{
              disabled: true
            }}
            wheel={{
              disabled: true
            }}
            pinch={{
              disabled: true
            }}
            panning={{
              disabled: true
            }}
            ref={mapRef}
          >
          <TransformComponent>
          {mapNodes &&
            <>
              {mapNodes.map((node, index) => {
                return (
                  <motion.div
                    name={node.title}
                    key={"mapNode" + index}
                    initial={{opacity: 0, scale: 1}}
                    variants={variants}
                    animate={node.active && node.active == node.active ? 'on' : 'off'}
                    transition={{ duration: 1, delay: node.active && node.active == node.active ? 3.5 : 0 }}
                    style={{left: Math.abs(convertPct("x", node.x)), top: Math.abs(convertPct("y", node.y))}}
                    className="mapX"
                  >
                    <img src="/x.png" className="xMark" />
                  </motion.div>
                )
              })}
              </>
            }
              
              <img src="/map/map.jpg" className="mapImage" ref={mapImageRef} width={1920} height={1920} />
              
          </TransformComponent>
        </TransformWrapper>
      </div>
    </div>
    </>
  );
};

export default Map;