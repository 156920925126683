import React, {
   useState,
   useContext,
   useCallback,
   useEffect,
   useRef,
} from "react";
import { SocketContext } from "../context/socket";
import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";

const Scoreboard = () => {
   const socket = useContext(SocketContext);
   const isInitialMount = useRef(true);

   const [loaded, setLoaded] = useState(false);
   const [globals, setGlobals] = useState();

   useEffect(() => {
      socket.emit("getData");
   }, []);

   useEffect(() => {
      socket.on("setGlobals", (json) => {
         setGlobals(json);
      });
   }, [socket]);

   const variants = {
      on: {
         opacity: 0.8,
         scale: 1,
      },
      off: {
         opacity: 0,
         scale: 3,
      },
   };

   return (
      <>
         <div style={{ height: window.innerHeight }}>
            {globals && (
               <div
                  className='screenContainer'
                  style={{
                     width: 1920,
                     height: 1080,
                     backgroundImage: "url(/viewer/bkgnd-scoreboard.jpg)",
                     backgroundSize: "100%",
                  }}
               >
                  <AnimatePresence>
                     <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.4 }}
                        className='scoreboardDataRow'
                     >
                        <div className='rowTitle'>Riddle Solved</div>
                        <motion.div
                           className='rowValue'
                           initial={{ opacity: 0, scale: 1.3 }}
                           animate={{ opacity: 1, scale: 1 }}
                           exit={{ opacity: 0, scale: 1.3 }}
                           transition={{ duration: 0.2, delay: 0.1 }}
                        >
                           {globals.riddlesSolved}
                        </motion.div>
                     </motion.div>
                  </AnimatePresence>

                  <AnimatePresence>
                     <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.4 }}
                        className='scoreboardDataRow'
                     >
                        <div className='rowTitle'>Ships Sunk</div>
                        <motion.div
                           className='rowValue'
                           initial={{ opacity: 0, scale: 1.3 }}
                           animate={{ opacity: 1, scale: 1 }}
                           exit={{ opacity: 0, scale: 1.3 }}
                           transition={{ duration: 0.2, delay: 0.1 }}
                        >
                           {globals.scuttled}
                        </motion.div>
                     </motion.div>
                  </AnimatePresence>

                  <AnimatePresence>
                     <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.4 }}
                        className='scoreboardDataRow'
                     >
                        <div className='rowTitle'>Score Multiplier</div>
                        <motion.div
                           className='rowValue'
                           initial={{ opacity: 0, scale: 1.3 }}
                           animate={{ opacity: 1, scale: 1 }}
                           exit={{ opacity: 0, scale: 1.3 }}
                           transition={{ duration: 0.2, delay: 0.1 }}
                        >
                           {globals.multiplier}
                        </motion.div>
                     </motion.div>
                  </AnimatePresence>

                  <AnimatePresence>
                     <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.4 }}
                        className='scoreboardDataRow'
                     >
                        <div className='rowTitle'>Total Points</div>
                        <motion.div
                           className='rowValue'
                           initial={{ opacity: 0, scale: 1.3 }}
                           animate={{ opacity: 1, scale: 1 }}
                           exit={{ opacity: 0, scale: 1.3 }}
                           transition={{ duration: 0.2, delay: 0.1 }}
                        >
                           {globals.score}
                        </motion.div>
                     </motion.div>
                  </AnimatePresence>

                  <AnimatePresence>
                     <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.4 }}
                        className='scoreboardDataRow'
                     >
                        <div className='rowTitle'>Evil Fleet</div>
                        <div className='rowValue'>
                           <AnimatePresence>
                              <motion.div
                                 initial={{ opacity: 0, scale: 1.3 }}
                                 animate={{ opacity: 1, scale: 1 }}
                                 exit={{ opacity: 0, scale: 1.3 }}
                                 transition={{ duration: 0.2, delay: 0.1 }}
                              >
                                 {globals.boatSloop1Sunk && (
                                    <motion.img
                                       initial={{ opacity: 0, scale: 1.3 }}
                                       animate={{ opacity: 0.9, scale: 1 }}
                                       exit={{ opacity: 0, scale: 1.3 }}
                                       transition={{
                                          duration: 0.2,
                                          delay: 0.1,
                                       }}
                                       src='/viewer/icon-boat-x.png'
                                       className='boatX'
                                    />
                                 )}
                                 <img src='/viewer/icon-sloop.png' />
                              </motion.div>
                              }
                           </AnimatePresence>
                           <AnimatePresence>
                              <motion.div
                                 initial={{ opacity: 0, scale: 1.3 }}
                                 animate={{ opacity: 1, scale: 1 }}
                                 exit={{ opacity: 0, scale: 1.3 }}
                                 transition={{ duration: 0.2, delay: 0.1 }}
                              >
                                 {globals.boatEvilGalleonSunk && (
                                    <motion.img
                                       initial={{ opacity: 0, scale: 1.3 }}
                                       animate={{ opacity: 0.9, scale: 1 }}
                                       exit={{ opacity: 0, scale: 1.3 }}
                                       transition={{
                                          duration: 0.2,
                                          delay: 0.1,
                                       }}
                                       src='/viewer/icon-boat-x.png'
                                       className='boatX'
                                    />
                                 )}
                                 <img src='/viewer/icon-galleon.png' />
                              </motion.div>
                           </AnimatePresence>
                           <AnimatePresence>
                              <motion.div
                                 initial={{ opacity: 0, scale: 1.3 }}
                                 animate={{ opacity: 1, scale: 1 }}
                                 exit={{ opacity: 0, scale: 1.3 }}
                                 transition={{ duration: 0.2, delay: 0.1 }}
                              >
                                 {globals.boatSloop2Sunk && (
                                    <motion.img
                                       initial={{ opacity: 0, scale: 1.3 }}
                                       animate={{ opacity: 0.9, scale: 1 }}
                                       exit={{ opacity: 0, scale: 1.3 }}
                                       transition={{
                                          duration: 0.2,
                                          delay: 0.1,
                                       }}
                                       src='/viewer/icon-boat-x.png'
                                       className='boatX'
                                    />
                                 )}
                                 <img src='/viewer/icon-sloop.png' />
                              </motion.div>
                           </AnimatePresence>
                        </div>
                     </motion.div>
                  </AnimatePresence>

                  <AnimatePresence>
                     <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.4 }}
                        className='scoreboardDataRow'
                     >
                        <div className='rowTitle'>Drops</div>
                        <motion.div
                           className='rowValue'
                           initial={{ opacity: 0, scale: 1.3 }}
                           animate={{ opacity: 1, scale: 1 }}
                           exit={{ opacity: 0, scale: 1.3 }}
                           transition={{ duration: 0.2, delay: 0.1 }}
                        >
                           <motion.img
                              src='/viewer/coin.png'
                              width='63'
                              initial={{ rotateY: 0 }}
                              animate={{ rotateY: 360 }}
                              transition={{
                                 repeat: Infinity,
                                 duration: 4,
                                 ease: "linear",
                              }}
                           />{" "}
                           {globals.drops}
                        </motion.div>
                     </motion.div>
                  </AnimatePresence>
               </div>
            )}
         </div>
      </>
   );
};

export default Scoreboard;
